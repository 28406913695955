import React from "react";
import { Avatar } from 'antd';

class SmallVideoComponent extends React.Component{
    constructor(props){
        super(props);
		this.canvasRef = React.createRef();
        this.state = {
       displayMainVideo:true
            };
       	
       }
        handleshow() {
               this.setState({
                   displayMainVideo:true
               })
           }
           handlehide() {
               this.setState({
                   displayMainVideo:false
               })
           }
       
    
    componentDidMount = () => {
		 this.drawCircle(); // 组件挂载后绘制圆圈
        const {stream} = this.props;
        this.video.srcObject = stream;
    }
    componentDidUpdate(prevProps) {
    	 
    	  const { MirrorOnline,DesktopOnline, } = this.props;
          if (prevProps.MirrorOnline !== MirrorOnline) {
               this.drawCircle();
          }
    	  if (prevProps.DesktopOnline !== DesktopOnline) {
    	       this.drawCircle();
    	  }
      }
    
    drawCircle = () => {
    	
    		const canvas = this.canvasRef.current;
    		const context = canvas.getContext('2d');
    		// 清空画布
    		context.clearRect(0, 0, canvas.width, canvas.height);
           const { MirrorOnline,DesktopOnline,name } = this.props;
    	   
    	   const name00=sessionStorage.getItem("userName")
    	   if (name === null || name === undefined || name === "") {
			    context.fillStyle =  'red';
    	   		 setTimeout(this.drawCircle, 1000);
    	    }else if(name === name00) {
    	           context.fillStyle = MirrorOnline ? 'green' : 'red';
    	      } else if (name === name00 + "分享屏幕") {
    	           context.fillStyle = DesktopOnline ? 'green' : 'red';
    	      } else {
    	           context.fillStyle =  'green';
    	      }
           context.beginPath();
           context.arc(20, 20, 10, 0, Math.PI * 2); // 圆心在 (10, 10)，半径为 5
           context.fill();
           context.stroke(); // 可选：绘制边框
       }
    componentWillUnmount = () => {
        this.video.srcObject = null;
    }

    handleClick = () => {
        // let {id,index} = this.props;
        // this.props.onClick({id,index});
    }
	changeColor= () => {
		
         const { name } = this.props;
		const name00=sessionStorage.getItem("userName")
		if (name === null || name === undefined || name === "") {
						    
		 }else if(name === name00) {
		
		       this.props.MirrorOnlineClick()
			   
		   } else if (name === name00 + "分享屏幕") {
			   
		       this.props.DesktopOnlineClick()
		   } else {
		       
		   }
    }
    render = () => {
        const { id,index,stream,name,muted,MirrorOnline,	DesktopOnline } = this.props;
		
        return (
            <div>
			{ /* <div onClick={this.handleClick} className='small-video-div' style={{display:"none"}}>
			*/}
            	{
                muted?
            	
            	<div className='small-video-div' style={{display:"none"}}>
            	    <video
						  onClick={this.props.onChangeVideoPosition.bind(this, { id, index })}
						    ref={ref => {
						        this.video = ref;
						    }}
						    id={id}
						    autoPlay
						    playsInline
						    className="small-video-size"
						/>
            	        ref={ref =>{
            	            this.video = ref;
            	        }}
            	        id={id}
            	        autoPlay
            	        playsInline
            	       
            	        className="small-video-size"
            	    />
            	    <div className='small-video-id-div'>
            	        <a className='small-video-id-a'>{name}</a>
            	    </div>
            	</div>
                : <div  className='small-video-div'>
                    <video
					  onClick={this.props.onChangeVideoPosition.bind(this, { id, index })}
					    ref={ref => {
					        this.video = ref;
					    }}
					    id={id}
					    autoPlay
					    playsInline
					    className="small-video-size"
					/>
                        ref={ref =>{
                            this.video = ref;
                        }}
                        id={id}
                        autoPlay
                        playsInline
                       
                        className="small-video-size"
                    />
                   
                    <div className='small-video-id-div' onClick={this.changeColor}>
                        <a className='small-video-id-a'>{name}<canvas ref={this.canvasRef} width="40" height="40"></canvas></a>
                    </div>
                </div>
            	}
            </div>
        )
    }
}

export default SmallVideoComponent;