import React, { useState } from 'react';
import { Spin, notification } from "antd";
import "../styles/css/meeting.scss";
import { LocalVideoComponent, BigVideoComponent, SmallVideoComponent } from "./video";
import { LocalStream } from "ion-sdk-js";
import md5 from 'md5'; // 导入 md5 库
import Pusher from 'aliyun-rts-pusher';
const MyContext = React.createContext();
const pushClient = AliRTSPusher.createClient();
class MeetingPage extends React.Component {
	
    static contextType = MyContext;
    constructor(props) {
        super(props)

        this.state = {
			 isPushing: false,
            streams: [],
            streams2: [],
            localStream: null,
            localStream2: null,
            localScreen: null,
            audioMuted: false,
            videoMuted: false,
            videoMutedBig: false,
            date: new Date(),
            displayMainVideo: true
			
        };
		 pushClient.on('error', (err) => { console.log(err.errorCode) });
    }
	
	handleMouseEnter = () => {
	    setIsVisible(true);
	  };
	
	 handleMouseLeave = () => {
	    setIsVisible(false);
	  }
    handleshow() {
        this.setState({
            displayMainVideo: true
        })
    }
    handlehide() {
        this.setState({
            displayMainVideo: false
        })
    }
    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            1000
        );
		
		let { localStream, localStream2, streams } = this.state;
		
    }
    tick() {
        let { localStream, localStream2, streams } = this.state;

        streams.map(async item => {
            item.name = sessionStorage.getItem(item.id) ? sessionStorage.getItem(item.id) : ""
        });
        this.setState({
            streams: streams,
            streams2: streams
        });
    }
    componentWillUnmount() {
        clearInterval(this.timerID);
		
		
    }
    cleanUp = async () => {

        let { localStream, localStream2,streams } = this.state;
        await this.setState({ localStream: null,localStream2: null, localScreen: null, streams: [] });

        streams.map(async item => {
            await item.stream.unsubscribe();
        });

        await this.unpublish(localStream);
        await this.unpublish(localStream2);
    }

    unpublish = async stream => {
        if (stream) {
            await this.stopMediaStream(stream);
            await stream.unpublish();
        }
    }

    stopMediaStream = async (stream) => {
        let tracks = stream.getTracks();
        for (let i = 0, len = tracks.length; i < len; i++) {
            await tracks[i].stop();
        }
    }

    muteMediaTrack = (type, enabled) => {
        let { localStream } = this.state;
        if (!localStream) {
            return
        }
        if (enabled) {
            localStream.unmute(type)
        } else {
            localStream.mute(type)
        }

        if (type === "audio") {
            this.setState({ audioMuted: !enabled });
        } else if (type === "video") {
            this.setState({ videoMuted: !enabled });
        }
    }
    muteMediaTrackBig = (type, enabled) => {
        let { localStream2 } = this.state;
        if (!localStream2) {
            return
        }
        if (enabled) {
            localStream2.unmute(type)
        } else {
            localStream2.mute(type)
        }

        if (type === "video") {
            this.setState({ videoMutedBig: !enabled });
        }
    }
 getCookie=(name)=>{
      var arr=document.cookie.split('; ');
      var i=0;
      for(i=0;i<arr.length;i++)
      {
          var arr2=arr[i].split('=');
          
          if(arr2[0]==name)
          {
              return arr2[1];
          }
      }
      return '';//如果没有就返回空值
  }
   publishLocalStream = async () => {
       const { client, settings } = this.props;
       let { localStream,localStream2 } = this.state;
       let streams = this.state.streams;
       LocalStream.getUserMedia({
           codec: settings.codec.toUpperCase(),
           bandwidth: 256,
           audio:{ deviceId: settings.selectedAudioDevice ? { exact: settings.selectedAudioDevice } : undefined },
           video: { width: 640, height: 480, deviceId: settings.selectedVideoDevice ? { exact: settings.selectedVideoDevice } : undefined },
           name: sessionStorage.getItem('userName') ? sessionStorage.getItem('userName'): ""
       }).then((stream) => {
           if (stream.id) {
               let streamsobj1 = {
                   streamid: stream.id,
                   userName: sessionStorage.getItem('userName') ? sessionStorage.getItem('userName'): ""
               }
               sessionStorage.setItem("streamsobj1", JSON.stringify(streamsobj1))
           }
           localStream = stream;
		   
           // client.publish(stream);
           this.setState({ localStream });
       })
           .catch((e) => {
           });
       this.muteMediaTrack("video", this.props.localVideoEnabled);
   
           LocalStream.getUserMedia({
               codec: settings.codec.toUpperCase(),
               bandwidth: settings.bandwidth,
               audio: false,
               video: { width: 3088, height: 2840, deviceId: settings.selectedVideoDeviceLocal ? { exact: settings.selectedVideoDeviceLocal } : undefined },
           }).then((stream) => {
               let name = "";
               localStream2 = stream;
               if (stream.id) {
                   let streamsobj2 = {
                       streamid: stream.id,
                       userName: sessionStorage.getItem('userName') ? sessionStorage.getItem('userName'): ""
   
                   }
                   sessionStorage.setItem("streamsobj2", JSON.stringify(streamsobj2))
               }
   
               // client.publish(stream);
               this.setState({ localStream2 });
               sessionStorage.setItem("streamId", stream.id)
               streams.push({ id: stream.id, stream: stream, name: name });
               this.setState({ streams });
           })
               .catch((e) => {
   
                   console.log("handleLocalStream error => " + settings.selectedAudioDevice + settings.selectedVideoDevice);
                   console.log("handleLocalStream error => " + e);
               });
      
       
   
       this.muteMediaTrack("video", this.props.localVideoEnabled);
	   client.ontrack = (track, stream) => {
	       console.log("go track", track.id, "for stream", stream.id);
	       track.onunmute = () => {
	           console.log("onunmute:::" + stream.id);
	           if (track.kind === "video") {
	               if (!this.hasStream(stream)) {
	                   let name = "";
	                   streams.push({ id: stream.id, stream: stream, name: name });
	                   this.setState({ streams });
	   
	                   stream.onremovetrack = () => {
	                       console.log("onremovetrack::" + stream.id);
	                       streams = streams.filter(item => item.id !== stream.id);
	                       this.setState({
	                           streams: streams,
	                       });
	                   }
	               }
	           }
	           this.updateMuteStatus(stream, false);
	       };
	   
	       track.onmute = () => {
	           console.log("onmute:::" + stream.id);
	           this.updateMuteStatus(stream, true);
	       }
	   }
		setTimeout(() => {
		    
		}, 1000)
   }
updateVideoSettings1 = async () => {
        let { localStream, client } = this.state;
        let getUserMediaOptions = {
            codec: this.props.settings.codec.toUpperCase(),
            resolution: "qvga",
            bandwidth: this.props.settings.bandwidth,
            audio: { deviceId: this.props.settings.selectedAudioDevice ? { exact: this.props.settings.selectedAudioDevice } : undefined },
            video: { width: 320, height: 180, deviceId: this.props.settings.selectedVideoDevice ? { exact: this.props.settings.selectedVideoDevice } : undefined },
            name: sessionStorage.getItem('userName')
        };

        try {
            const newLocalStream = await LocalStream.getUserMedia(getUserMediaOptions);
            localStream.getTracks().forEach(track => {
                track.stop();
            });
            localStream = newLocalStream;
            client.unpublish();
            client.publish(localStream);
            this.setState({ localStream });
        } catch (error) {
            console.error("Error updating video settings:", error);
        }
    }
updateVideoSettings2 = async () => {
        let { localStream, client } = this.state;
        let getUserMediaOptions = {
            codec: this.props.settings.codec.toUpperCase(),
            audio: { deviceId: this.props.settings.selectedAudioDevice ? { exact: this.props.settings.selectedAudioDevice } : undefined },
            name: sessionStorage.getItem('userName') ,
			resolution: "shd",
			bandwidth: this.props.settings.bandwidth,
			video: { width: 2592, height: 2048, deviceId: this.props.settings.selectedVideoDeviceLocal ? { exact: this.props.settings.selectedVideoDeviceLocal } : undefined },
        };

        try {
            const newLocalStream = await LocalStream.getUserMedia(getUserMediaOptions);
            localStream.getTracks().forEach(track => {
                track.stop();
            });
            localStream = newLocalStream;
            client.unpublish();
            client.publish(localStream);
            this.setState({ localStream });
        } catch (error) {
            console.error("Error updating video settings:", error);
        }
    }

toggleCameraAndMicrophone1 = ( toggleCamera, toggleMicrophone) => {
			 let { localStream, localStream2 } = this.state;
		    const videoTracks = localStream.getVideoTracks();
		    const audioTracks = localStream.getAudioTracks();
		
		    if (toggleCamera) {
		        videoTracks.forEach(track => {
		            track.enabled = !track.enabled; // 切换摄像头状态
		        });
		    }
		
		    if (toggleMicrophone) {
		        audioTracks.forEach(track => {
		            track.enabled = !track.enabled; // 切换麦克风状态
		        });
		    }
		}
toggleCameraAndMicrophone2 = ( toggleCamera) => {
			 let { localStream, localStream2 } = this.state;
		    const videoTracks = localStream2.getVideoTracks();
		    const audioTracks = localStream2.getAudioTracks();
		
		    if (toggleCamera) {
		        videoTracks.forEach(track => {
		            track.enabled = !track.enabled; // 切换摄像头状态
		        });
				this.setState({ videoMutedBig: !toggleCamera });
		    }
			
		 
		}
toggleCameraAndMicrophone1True = ( ) => {
			 let { localStream, localStream2 } = this.state;
		    const videoTracks = localStream.getVideoTracks();
		    const audioTracks = localStream.getAudioTracks();
			
		   videoTracks.forEach(track => {
		       track.enabled = true; // 切换摄像头状态
		   });
		
		    audioTracks.forEach(track => {
		        track.enabled = true; // 切换麦克风状态
		    });
			 this.setState({ videoMuted:!true });
			 this.setState({ audioMuted: !true});
		}
toggleCameraAndMicrophone1False = ( ) => {
					 let { localStream, localStream2 } = this.state;
				    const videoTracks = localStream.getVideoTracks();
				    const audioTracks = localStream.getAudioTracks();
				
				   videoTracks.forEach(track => {
				       track.enabled = false; // 切换摄像头状态
				   });
				
				   audioTracks.forEach(track => {
				       track.enabled = true; // 切换麦克风状态
				   });
				   this.setState({ videoMuted:!false });
				   this.setState({ audioMuted: !true});
				}
toggleCameraAndMicrophone2True = ( ) => {
			 let { localStream, localStream2 } = this.state;
		    const videoTracks = localStream2.getVideoTracks();
		    const audioTracks = localStream2.getAudioTracks();
		
		   videoTracks.forEach(track => {
		       track.enabled = true; // 切换摄像头状态
		   });
			this.setState({ videoMutedBig: !true });
		 
		}
		toggleCameraAndMicrophone2False = ( ) => {
			 let { localStream, localStream2 } = this.state;
		    const videoTracks = localStream2.getVideoTracks();
		    const audioTracks = localStream2.getAudioTracks();
		
		    videoTracks.forEach(track => {
		        track.enabled = false; // 切换摄像头状态
		    });
			this.setState({ videoMutedBig: !false });
		 
		}
		 generateRandomString() {
		  const characters = 'abcdef0123456789';
		  let randomString = '';
		
		  for (let i = 0; i < 8; i++) {
		    randomString += characters.charAt(Math.floor(Math.random() * characters.length));
		  }
		
		  randomString += '-';
		
		  for (let i = 0; i < 4; i++) {
		    randomString += characters.charAt(Math.floor(Math.random() * characters.length));
		  }
		
		  randomString += '-';
		
		  for (let i = 0; i < 4; i++) {
		    randomString += characters.charAt(Math.floor(Math.random() * characters.length));
		  }
		
		  randomString += '-';
		
		  for (let i = 0; i < 4; i++) {
		    randomString += characters.charAt(Math.floor(Math.random() * characters.length));
		  }
		
		  randomString += '-';
		
		  for (let i = 0; i < 12; i++) {
		    randomString += characters.charAt(Math.floor(Math.random() * characters.length));
		  }
		
		  return randomString;
		}
		startScreenCapture = async () => {
		  const streamId = await pushClient.startScreenCapture();
		  if (streamId) {
		       // const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
			   const { client, settings } = this.props;
			   let { localStream,localStream2 } = this.state;
			   let streams = this.state.streams;
			   LocalStream.getUserMedia({
			       codec: settings.codec.toUpperCase(),
			       bandwidth: 256,
			       audio:{ deviceId: settings.selectedAudioDevice ? { exact: settings.selectedAudioDevice } : undefined },
			       video: false
			   }).then((stream) => {
			      pushClient.setVideoQuality('1080p_1');
			      pushClient.startMicrophone(stream);
			      pushClient.startPush(this.pushUrl('out.xytechsz.com', 'd97xDsyH0bHUCvjF', 86400, 80035, 80035));
			      console.log(this.pushUrl('out.xytechsz.com', 'd97xDsyH0bHUCvjF', 86400, 80035, 80035))
			      this.setState({ isPushing: true });
			   })
			       .catch((e) => {
			       });
		      
		      } else {
		        console.warn('Stream ID is empty or undefined. Not executing function a.');
		      }
			
		}
		pushUrl = (pushDomain, pushKey, expireTime, appName, streamName) => {
		  const timeStamp = Math.floor(Date.now() / 1000) + parseInt(expireTime);        
		
		  let pushUrl;
		  if (pushKey!== '') {
		    const sstring = `/${appName}/${streamName}-${timeStamp}-0-0-${pushKey}`;
		    pushUrl = `artc://${pushDomain}/${appName}/${streamName}?auth_key=${timeStamp}-0-0-${md5(sstring)}`;
		  } else {
		    pushUrl = `artc://${pushDomain}/${appName}/${streamName}`;
		  }
		  return pushUrl;
		}
	startPushStream = async (enabled) => {
		try {
			
		  const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
		  pushClient.setVideoQuality('1080p_1');
		  pushClient.startMicrophone(audioStream);
		  pushClient.startPush(this.pushUrl('out.xytechsz.com', 'd97xDsyH0bHUCvjF', 86400, 80035, 80035));
				
				console.log(this.pushUrl('out.xytechsz.com', 'd97xDsyH0bHUCvjF', 86400, 80035, 80035))
		  this.setState({ isPushing: true });
		} catch (error) {
		  console.error('Error accessing audio stream:', error);
		}
	}
		stopPushStream = async (enabled) => {
			pushClient.stopMicrophone();
			pushClient.stopPush();
			this.setState({ isPushing: false });
		}
	handleScreenLive = async (enabled) => {
	 
	   let { localScreen, streams } = this.state;
	   const { client, settings, screenSharingCallBack } = this.props;
	    if (enabled) {
	       this.startScreenCapture()
							
	    } else {
		pushClient.stopMicrophone();
		pushClient.stopPush();
		this.setState({ isPushing: false });
	  
	        
	    }
	   
	}	
    handleScreenSharing = async (enabled) => {
        let { localScreen, streams } = this.state;
        const { client, settings, screenSharingCallBack } = this.props;
        let aa = sessionStorage.getItem("userName") ? sessionStorage.getItem("userName") : "admin";
        let name = aa + "分享屏幕"
        if (enabled) {
            localScreen = await LocalStream.getDisplayMedia({
                codec: settings.codec.toUpperCase(),
                // resolution: settings.resolution,
                bandwidth: settings.bandwidth,
                audio: false,
                name: name,
            });
            // await client.publish(localScreen);
			  this.setState({ localScreen });
            let track = localScreen.getVideoTracks()[0];
            if (track) {
                track.addEventListener("ended", () => {
                    screenSharingCallBack(false);
                    this.handleScreenSharing(false);
                });
            }
            streams.push({ id: localScreen.id, stream: localScreen, name: name, muted: false });
    		 let ShareScreen = {
    		                    streamid: localScreen.id,
    		                    userName: sessionStorage.getItem('userName') ? sessionStorage.getItem('userName')+"分享屏幕"  : ""
    		                }
    		                sessionStorage.setItem("ShareScreen", JSON.stringify(ShareScreen))
							
        } else {
            if (localScreen) {
                this.unpublish(localScreen);
    			
                streams = streams.filter(item => item.name.indexOf("分享屏幕") ==-1);
    			streams.forEach(item => console.log(item));	
                localScreen = null;
            }
            // window.location.reload();
        }
        this.setState({
            streams: streams,
            localScreen: localScreen,
        })
    }
	
    //是否有相同的流
    hasStream = (stream) => {
		
		
        let flag = false;
        let streams = this.state.streams;
        streams.forEach((item) => {
            if (item.id == stream.id) {
                flag = true;
            }
        });
		
		
        return flag;
    }
	
    updateMuteStatus = (stream, muted) => {
		
        let streams = this.state.streams;
       
        streams.forEach((item) => {
            if (item.id == stream.id) {
                item.muted = muted;

            }
        });
        this.setState({
            streams: streams,
        });

    }
    onChangeVideoLocalPosition = data => {
        const { settings } = this.props;
        if (this.props.onMediaSettingsChanged !== undefined) {
            console.log(settings)
            this.props.onMediaSettingsChanged(
                settings.selectedAudioDevice,
                settings.selectedVideoDeviceLocal,
                settings.selectedVideoDevice,
                settings.resolution,
                settings.bandwidth,
                settings.codec);
        }
        history.go(0)
    }
	hideDiv = () => {
	  const div = document.getElementById("smallDivId");
	  if (div) {
	    div.style.visibility = "hidden";
	  }
	}
	showDiv = () => {
	  const div = document.getElementById("smallDivId");
	  if (div) {
	    div.style.visibility = "visible";
	  }
	}
	hideBigDiv = () => {
	  const div = document.getElementById("meeting-local-video-layout");
	  if (div) {
	    div.style.visibility = "hidden";
	  }
	}
	showBigDiv = () => {
	  const div = document.getElementById("meeting-local-video-layout");
	  if (div) {
	    div.style.visibility = "visible";
	  }
	}
	
    onChangeVideoPositionSam(id) {
        const streams = this.state.streams;
        let first = 0;
        let big = 0;
        for (let i = 0; i < streams.length; i++) {
            let item = streams[i];
            if (item.id == id) {
                big = i;
                break;
            }
        }

        let c = streams[first];
        streams[first] = streams[big];
        streams[big] = c;

        this.setState({ streams: streams });
    };
    onChangeVideoPosition = data => {
    
        let id = data.id;
        let index = data.index;
        console.log("onChangeVideoPosition id:" + id + " index:" + index);
    
        if (index == 0) {
            return;
        }
    
        const streams = this.state.streams;
        let first = 0;
        let big = 0;
        for (let i = 0; i < streams.length; i++) {
            let item = streams[i];
            if (item.id == id) {
                big = i;
                break;
            }
        }
    
        let c = streams[first];
        streams[first] = streams[big];
        streams[big] = c;
    
        this.setState({ streams: streams });
    };

    notification = (message, description) => {
        notification.info({
            message: message,
            description: description,
            placement: "bottomRight"
        });
    };
    onSendMessage = (envet) => {
        this.props.onSendMessage(envet)
    }
    render = () => {
        const { vidFit ,MeshOnline,MirrorOnline,DesktopOnline ,} = this.props;
        const {
            streams,
            localStream,
            localStream2,
            audioMuted,
            videoMuted,
            videoMutedBig,
			
			
        } = this.state;
        const id = this.props.uid;
        const hostState = this.props.hostState;
        const hostStatelocal = this.props.hostStatelocal;
        return (
            <div className="meeting-layout">
                {streams.length === 0 && (
                    <div className="meeting-layout-wating">
                        <Spin size="large" tip="等待其他人加入会议..." />
                    </div>
                )

                }
                {streams.map((item, index) => {
                    return index == 0 ? (
                        <BigVideoComponent
						MirrorOnlineClick ={this.props.MirrorOnlineClick}
						DesktopOnlineClick ={this.props.DesktopOnlineClick}
							MirrorOnline={MirrorOnline}
							DesktopOnline={DesktopOnline}
                            onSendMessage={this.onSendMessage}
                            key={item.id}
                            id={item.id}
                            name={item.name}
                            stream={item.stream}
                            muted={item.muted}
                            vidFit={vidFit}
                            ref={ref => {
                                this.bigVideo = ref;
                            }}
                        />
                    ) : (
                        ""
                    );
                })
                }
                {localStream && (
                    <div className="meeting-local-video-layout">
                        <LocalVideoComponent
						MeshOnlineClick ={this.props.MeshOnlineClick}
                            id={id + "-video"}
                            stream={localStream}
                            name="本地"
                            audioMuted={audioMuted}
                            videoMuted={videoMuted}
							MeshOnline={MeshOnline}
							onChangeVideoLocalPosition={this.onChangeVideoLocalPosition}
                            ref={ref => {
                                this.localVideo = ref;
                            }}
                        />
                    </div>
                )

                }
                <div className={this.state.displayMainVideo ? "small-video-list-div" : "small-video-list-div2"}  >
                    <div className="small-video-list">
                        {
                            streams.map((item, index) => {
                                return index > 0 ? (
                                    <SmallVideoComponent
									
									MirrorOnlineClick ={this.props.MirrorOnlineClick}
									DesktopOnlineClick ={this.props.DesktopOnlineClick}
										MirrorOnline={MirrorOnline}
										DesktopOnline={DesktopOnline}
                                        key={item.id}
                                        id={item.id}
                                        stream={item.stream}
                                        name={item.name}
                                        muted={item.muted}
                                        videoCount={streams.length}
                                        collapsed={this.props.collapsed}
                                        index={index}
                                        onChangeVideoPosition={this.onChangeVideoPosition}
                                        ref={ref => {
                                            this.smallVideo = ref;
                                        }}
                                    />
                                ) : (
                                    ""
                                );
                            })}


                    </div>
                </div>
            </div>
        )
    }
}

export default MeetingPage;
