import React from "react";
import { Avatar } from 'antd';
import PenMeeting from "../pen_meeting";

import ResizeObserver from 'resize-observer-polyfill';
//MediaStream对象
let stream;
//画布对象
let canvas;
//画布2D内容
let context;
let drawstate=false;
var spotList=[]

class BigVideoComponent extends React.Component{
	
	constructor(props) {
	    super(props);
		this.canvasRef = React.createRef();
		
	    this.state = {
			 value: 1,
			 videoWidth:0,
			 videoHeight:0,
			 marginTop:0,
			 marginLeft:0,
			 displayMainVideo:true
	     };
		
	}
	takeSnap = async (e) => {
		const { id} = this.props;
		console.log(this.refs['canvas2']); // 检查 canvas 是否存在
	  //获取画布对象
	  let canvas = this.refs['canvas2'];
	  //设置画面宽度
	  canvas.width = document.getElementById(id).videoWidth;
	  //设置画面高度
	  canvas.height = document.getElementById(id).videoHeight;
	  //根据视频对象,xy坐标,画布宽,画布高绘制位图
	  canvas.getContext('2d').drawImage(document.getElementById(id), 0, 0, canvas.width, canvas.height);
	  let base64Image = canvas.toDataURL("image/jpeg");
	  sessionStorage.setItem("base64Image", base64Image);
	}
	 handleshow() {
	        this.setState({
	            displayMainVideo:true
	        })
	    }
	    handlehide() {
	        this.setState({
	            displayMainVideo:false
	        })
	    }
	
	setValue(msg){
		console.log(msg)
	}
	setDrawState=(event)=>{
		drawstate= !drawstate
	}
	
	componentDidMount() {
		 this.drawCircle(); // 组件挂载后绘制圆圈
		 
		const { id} = this.props;
		
		var  that=this
		document.getElementById(id).oncanplay = function () {
			
			var videoWidth=0
			var videoHeight=0
			var vclientWidth=document.getElementById(id).clientWidth
			var vclientHeight=document.getElementById(id).clientHeight
			
		    // 触发canplay事件之后获取video的宽高为640,480
			videoWidth=document.getElementById(id).videoWidth
			videoHeight=document.getElementById(id).videoHeight
			
			if(videoWidth/videoHeight>= vclientWidth/vclientHeight){
				document.getElementById("canvasshow").setAttribute("width", vclientWidth)
				
				var vtheight=vclientWidth*videoHeight/videoWidth
				document.getElementById("canvasshow").setAttribute("height", vtheight)
				document.getElementById("canvasshow").style.marginTop=(vclientHeight-vtheight)/2+"px"
				
				 that.setState({
					 videoWidth:videoWidth,
					 videoHeight:videoHeight,
					 marginTop:(vclientHeight-vtheight)/2
				 })
				 document.getElementById("canvasshow").style.marginLeft="0px"
			}else{
				
				document.getElementById("canvasshow").setAttribute("height", vclientHeight)
			
				var vtwidth=vclientHeight*videoWidth/videoHeight
				
				document.getElementById("canvasshow").setAttribute("width", vtwidth)
				document.getElementById("canvasshow").style.marginLeft=(vclientWidth-vtwidth)/2+"px"
				 document.getElementById("canvasshow").style.marginTop="0px"
				 that.setState({
					 videoWidth:videoWidth,
					 videoHeight:videoHeight,
					 marginLeft:(vclientWidth-vtwidth)/2
				 })
			}
			
		    
			 
			 
			
		  }
		
		
	    canvas = this.refs['canvas'];
	    this.startCaptureCanvas();
		
		const {stream} = this.props;
		this.video.srcObject = stream;
		
		const ro = new ResizeObserver((entries, observer) => {
		    try {
		    	for (const entry of entries) {
		    		var content = document.getElementById("canvasshow").getContext("2d")
					
		    	    const {left, top, width, height} = entry.contentRect;
		    		var data = content.getImageData(0, 0, width, height)
		    		
		    		if(that.state.videoWidth/that.state.videoHeight>= document.getElementById(id).clientWidth/document.getElementById(id).clientHeight){
		    			document.getElementById("canvasshow").setAttribute("width", document.getElementById(id).clientWidth)
		    			
		    			var vtheight=document.getElementById(id).clientWidth*that.state.videoHeight/that.state.videoWidth
		    			document.getElementById("canvasshow").setAttribute("height", vtheight)
		    			document.getElementById("canvasshow").style.marginTop=(document.getElementById(id).clientHeight-vtheight)/2+"px"
		    			 document.getElementById("canvasshow").style.marginLeft="0px"
		    			that.setState({
		    							 marginTop:(document.getElementById(id).clientHeight-vtheight)/2
		    			})
		    		}else{
		    			document.getElementById("canvasshow").setAttribute("height", document.getElementById(id).clientHeight)
		    			
		    			var vtwidth=document.getElementById(id).clientHeight*that.state.videoWidth/that.state.videoHeight
		    			document.getElementById("canvasshow").setAttribute("width", vtwidth)
		    			document.getElementById("canvasshow").style.marginLeft=(document.getElementById(id).clientWidth-vtwidth)/2+"px"
		    			 document.getElementById("canvasshow").style.marginTop="0px"
		    			that.setState({
		    							 marginLeft:(document.getElementById(id).clientWidth-vtwidth)/2
		    			})
		    			
		    			
		    			
		    		}
		    		
		    		
		    		content.putImageData(data, 0, 0)
		    		 
		    	}
		    } catch (error) {
		    	console.log(error)
		    }
		});
		ro.observe(document.getElementById(id));
		
		
	}
	
	
	//开始捕获Canvas
	startCaptureCanvas = async (e) => {
		
			
	    this.drawLine();
	    
	}
	drawLine0 = () => {
		const { id} = this.props;
		console.log(document.getElementById(id).clientWidth)
		console.log(document.getElementById(id).clientHeight)
		
		
		
		document.getElementById("canvasshow").setAttribute("topmargin",200)
	    context = canvas.getContext("2d");
	
	    context.lineWidth =20;
		
		// 设置透明度
		context.globalAlpha = sessionStorage.getItem("setBrightness")/100; // 0.0 (完全透明) 到 1.0 (完全不透明)
		context.fillStyle =sessionStorage.getItem("color");
		context.strokeStyle =sessionStorage.getItem("color");
		// 计算中心点
		const centerX = canvas.width / 2;
		const centerY = canvas.height / 2;
		
		// 开始绘制倒 V
		context.beginPath();
		
		// 开始绘制第一条线段（从起点到第一个终点）
		      
		      context.moveTo(centerX - 50, centerY + 50);
		       context.lineTo(centerX, centerY - 50);  
		       
		       context.lineTo(centerX + 50, centerY + 50);
		        context.stroke();
	}
	drawLine = () => {
		const { id} = this.props;
		console.log(document.getElementById(id).clientWidth)
		console.log(document.getElementById(id).clientHeight)
		
		// document.getElementById("canvasshow").width = document.getElementById(id).clientWidth;
		// document.getElementById("canvasshow").height = document.getElementById(id).clientHeight;
		// document.getElementById("canvasshow").setAttribute("width", document.getElementById(id).clientWidth)
		// document.getElementById("canvasshow").setAttribute("height",  document.getElementById(id).clientHeight)
		
		document.getElementById("canvasshow").setAttribute("topmargin",200)
	    context = canvas.getContext("2d");
	
	    context.lineWidth =sessionStorage.getItem("pensize")?sessionStorage.getItem("pensize"): 2;
	    // context.lineWidth =82;
	    context.strokeStyle = sessionStorage.getItem("color");
		  // 0.0 (完全透明) 到 1.0 (完全不透明)
	    //监听画板鼠标按下事件 开始绘画
	    canvas.addEventListener("mousedown", this.startAction);
		//手机端按下
		canvas.addEventListener("touchstart",this.startActionPhone)  
	    //监听画板鼠标抬起事件 结束绘画
	    canvas.addEventListener("mouseup", this.endAction);
		//手机端移走手指  监听画板鼠标抬起事件 结束绘画
		canvas.addEventListener("touchend", this.endActionPhone);
	}
	clearCanvas2=(event)=>{
		
		context .clearRect(0,0,canvas.width,canvas.height);
	}
	clearCanvas=(event)=>{
		this.props.onSendMessage(JSON.stringify({
			type:"clearCanvas",
			data:spotList
		}));
		spotList.length=0;
		context .clearRect(0,0,canvas.width,canvas.height);
		
		// this.drawLine0();
	}
	
	drawCanvas=(event,colorType,pensize)=>{
		const { id} = this.props;
		let spotObjDraw=JSON.parse(event)
		// displayWidth
		var kjh=document.getElementById("canvasshow").clientHeight
		var kjw=document.getElementById("canvasshow").clientWidth
		
		var kjhClientHeight=spotObjDraw.clientHeight
		var kjwClientWidth=spotObjDraw.clientWidth
		
		var videoStateHeight=this.state.videoHeight
		var videoStateWidth=this.state.videoWidth
		
		var h0=kjh/kjhClientHeight
		var w0=kjw/kjwClientWidth
		
		// intrinsicWidth
		var zsh=document.getElementById(id).clientHeight;
		var zsw=document.getElementById(id).clientWidth;
		
		
		var xx1=(event.offsetX-(kjw-zsw*kjh/zsh)/2)*zsh/kjh
		var yy1=(event.offsetY-(kjh-zsh*kjw/zsw)/2)*zsw/kjw
		
		
		if(spotObjDraw.data.length>0){
			context.beginPath();
			context.moveTo(spotObjDraw.data[0].x*kjw/kjwClientWidth,spotObjDraw.data[0].y*kjh/kjhClientHeight )
			// context.moveTo(spotObjDraw.data[0].x,spotObjDraw.data[0].y)
			for(let i=1;i<spotObjDraw.data.length;i++ ){
				context.lineTo(spotObjDraw.data[i].x*kjw/kjwClientWidth,spotObjDraw.data[i].y*kjh/kjhClientHeight )
				// context.lineTo(spotObjDraw.data[i].x,spotObjDraw.data[i].y)
				// context.lineTo(spotObjDraw.data[i].x,spotObjDraw.data[i].y)
			}
			context.strokeStyle=colorType;
			context.lineWidth =pensize?pensize: 2;

			context.stroke();
			
		}
		// context .clearRect(0,0,canvas.width,canvas.height);
	}
	//鼠标按下事件
	
	// ontouchstart
	// changedTouches
	startActionPhone = (event) => {
		
		console.log(event)
		
		context.lineWidth =sessionStorage.getItem("pensize")?sessionStorage.getItem("pensize"): 2;
	    // context.lineWidth =82;
	    context.strokeStyle = sessionStorage.getItem("color");
		  // 0.0 (完全透明) 到 1.0 (完全不透明)
		const { id} = this.props;
		// displayWidth
		var kjh=document.getElementById("canvasshow").clientHeight
		var kjw=document.getElementById("canvasshow").clientWidth
		
		// intrinsicWidth
		var zsh=document.getElementById(id).clientHeight;
		var zsw=document.getElementById(id).clientWidth;
		
		
		// var xx1=(event.changedTouches[0].clientX-(kjw-zsw*kjh/zsh)/2)*zsh/kjh
		// var yy1=(event.changedTouches[0].clientY-(kjh-zsh*kjw/zsw)/2)*zsw/kjw
		var computedStyle = getComputedStyle(document.getElementById("canvasshow"), null);
		
		const container = document.getElementById("container");
		const containerRect = container.getBoundingClientRect();
		
		const canvasRect = canvas.getBoundingClientRect();
		const scaleX = canvas.width / canvasRect.width;
		const scaleY = canvas.height / canvasRect.height;
		
		
		let xx1 = 0;
		let yy1 = 0;
		if(this.state.videoWidth/this.state.videoHeight>= document.getElementById(id).clientWidth/document.getElementById(id).clientHeight){
			xx1 = (event.touches[0].clientX - containerRect.left) * scaleX;
			yy1 = (event.touches[0].clientY -this.state.marginTop) * scaleY;
		}else{
			xx1 = (event.touches[0].clientX - this.state.marginLeft) * scaleX;
			yy1 = (event.touches[0].clientY -containerRect.top) * scaleY;
		}
		// const xx1 = (event.touches[0].clientX*this.state.videoWidth/kjw - containerRect.left) * scaleX;
		// const yy1 = (event.touches[0].clientY*this.state.videoHeight/kjh - containerRect.top) * scaleY;
		// var xx1=event.changedTouches[0].clientX*this.state.videoWidth/kjw
		// var yy1=event.changedTouches[0].clientY*this.state.videoHeight/kjh-this.state.marginTop
		console.log(computedStyle.marginTop)
		console.log(this.state.marginTop)
		console.log(this.state.marginTop)
		console.log(event.changedTouches[0].clientX+"---"+event.changedTouches[0].clientY)
		// console.log(xx1+"---"+yy1)
		console.log(xx1+"---"+yy1)
	    //开始新的路径
	    context.beginPath();
	    //将画笔移动到指定坐标，类似起点
		 
		spotList.push({
			x:xx1*this.state.videoWidth/kjw,
			y:yy1*this.state.videoHeight/kjh
		})
		// context.moveTo(event.changedTouches[0].clientX,event.changedTouches[0].clientY)
		context.moveTo(xx1,yy1)
	    // context.moveTo(event.offsetX*(5/16), event.offsetY*(8/45));
	    //开始绘制
	    context.stroke();
		//手机端  移动手指
	    canvas.addEventListener("touchmove", this.moveActionPhone);
		
		
	}
	startAction = (event) => {
		console.log("ssss")
		console.log(event)
		context.lineWidth =sessionStorage.getItem("pensize")?sessionStorage.getItem("pensize"): 2;
	    // context.lineWidth =82;
	    context.strokeStyle = sessionStorage.getItem("color");
		  // 0.0 (完全透明) 到 1.0 (完全不透明)
		const { id} = this.props;
		// displayWidth
		var kjh=document.getElementById("canvasshow").clientHeight
		var kjw=document.getElementById("canvasshow").clientWidth
		
		// intrinsicWidth
		var zsh=document.getElementById(id).clientHeight;
		var zsw=document.getElementById(id).clientWidth;
		
		
		// var xx1=(event.offsetX-(kjw-zsw*kjh/zsh)/2)*zsh/kjh
		// var yy1=(event.offsetY-(kjh-zsh*kjw/zsw)/2)*zsw/kjw
		// var xx1=event.offsetX*kjw/this.state.videoWidth
		// var yy1=event.offsetY*kjh/this.state.videoHeight
		var xx1=event.offsetX*this.state.videoWidth/kjw
		var yy1=event.offsetY*this.state.videoHeight/kjh
		
	    //开始新的路径
	    context.beginPath();
		 
	    //将画笔移动到指定坐标，类似起点
		spotList.push({
			x:xx1,
			y:yy1
		})
		context.moveTo(event.offsetX,event.offsetY)
	    // context.moveTo(event.offsetX*(5/16), event.offsetY*(8/45));
	    //开始绘制
	    context.stroke();
	    //监听鼠标移动事件  
	    canvas.addEventListener("mousemove", this.moveAction);
		
		
		
	}
	
	//鼠标移动事件  
	moveAction = (event) => {
		
		const {id} = this.props;
		// displayWidth
		var kjh=document.getElementById("canvasshow").clientHeight
		var kjw=document.getElementById("canvasshow").clientWidth
		
		// intrinsicWidth
		var zsh=document.getElementById(id).clientHeight;
		var zsw=document.getElementById(id).clientWidth;
		
		
		// var xx1=(event.offsetX-(kjw-zsw*kjh/zsh)/2)*zsh/kjh
		// var yy1=(event.offsetY-(kjh-zsh*kjw/zsw)/2)*zsw/kjw
		// var xx1=event.offsetX*kjw/this.state.videoWidth
		// var yy1=event.offsetY*kjh/this.state.videoHeight
		var xx1=event.offsetX*this.state.videoWidth/kjw
		var yy1=event.offsetY*this.state.videoHeight/kjh
		context.lineTo(event.offsetX,event.offsetY)
		spotList.push({
			x:xx1,
			y:yy1
		})
	    //将画笔移动到结束坐标，类似终点
	    // context.lineTo(event.offsetX*(5/16), event.offsetY*(8/45));
		// console.log(event.offsetX, event.offsetY*(8/45))
		
	    //开始绘制
		  // 0.0 (完全透明) 到 1.0 (完全不透明)
	    context.stroke();
	}
	
	moveActionPhone = (event) => {
		 
		const {id} = this.props;
		// displayWidth
		var kjh=document.getElementById("canvasshow").clientHeight
		var kjw=document.getElementById("canvasshow").clientWidth
		
		// intrinsicWidth
		var zsh=document.getElementById(id).clientHeight;
		var zsw=document.getElementById(id).clientWidth;
		
		
		// var xx1=(event.changedTouches[0].clientX-(kjw-zsw*kjh/zsh)/2)*zsh/kjh
		// var yy1=(event.changedTouches[0].clientY-(kjh-zsh*kjw/zsw)/2)*zsw/kjw
		const container = document.getElementById("container");
		const containerRect = container.getBoundingClientRect();
		let canvas =document.getElementById("canvasshow")
		const canvasRect = canvas.getBoundingClientRect();
		const scaleX = canvas.width / canvasRect.width;
		const scaleY = canvas.height / canvasRect.height;
		let xx1 = 0;
		let yy1 = 0;
		if(this.state.videoWidth/this.state.videoHeight>= document.getElementById(id).clientWidth/document.getElementById(id).clientHeight){
			xx1 = (event.touches[0].clientX - containerRect.left) * scaleX;
			yy1 = (event.touches[0].clientY -this.state.marginTop) * scaleY;
		}else{
			xx1 = (event.touches[0].clientX - this.state.marginLeft) * scaleX;
			yy1 = (event.touches[0].clientY -containerRect.top) * scaleY;
		}
		// const xx1 = (event.touches[0].clientX*this.state.videoWidth/kjw - containerRect.left) * scaleX;
		// const yy1 = (event.touches[0].clientY*this.state.videoHeight/kjh - containerRect.top) * scaleY;
		// var xx1=event.changedTouches[0].clientX*this.state.videoWidth/kjw
		// var yy1=event.changedTouches[0].clientY*this.state.videoHeight/kjh-this.state.marginTop
		
		
		context.lineTo(xx1,yy1)
		// context.lineTo(event.changedTouches[0].clientX,event.changedTouches[0].clientY)
		spotList.push({
			x:xx1*this.state.videoWidth/kjw,
			y:yy1*this.state.videoHeight/kjh
		})
	    //将画笔移动到结束坐标，类似终点
	    // context.lineTo(event.offsetX*(5/16), event.offsetY*(8/45));
		// console.log(event.offsetX, event.offsetY*(8/45))
		
	    //开始绘制
	    context.stroke();
	}
	//鼠标抬起事件 
	endActionPhone= () => {
		console.log(spotList)
		this.props.onSendMessage(JSON.stringify({
			type:"drawLine",
			data:spotList,
			clientHeight:this.state.videoHeight,
			clientWidth:this.state.videoWidth
		}));
		spotList.length=0;
	    //移除鼠标移动事件
	    canvas.removeEventListener("touchend", this.moveActionPhone);
		
		
	}
	endAction = () => {
		console.log(this.state.videoHeight)
		console.log(this.state.videoWidth)
		this.props.onSendMessage(JSON.stringify({
			type:"drawLine",
			data:spotList,
			clientHeight:this.state.videoHeight,
			clientWidth:this.state.videoWidth
		}));
		spotList.length=0;
	    //移除鼠标移动事件
	    canvas.removeEventListener("mousemove", this.moveAction);
		
		
	}
	
	
	componentDidUpdate(prevProps) {
		 
		  const { MirrorOnline,DesktopOnline, } = this.props;
	      if (prevProps.MirrorOnline !== MirrorOnline) {
	           this.drawCircle();
	      }
		  if (prevProps.DesktopOnline !== DesktopOnline) {
		       this.drawCircle();
		  }
	  }
	changeColor= () => {
		
	     const { name } = this.props;
		const name00=sessionStorage.getItem("userName")
		if (name === null || name === undefined || name === "") {
						    
		 }else if(name === name00) {
		
		       this.props.MirrorOnlineClick()
			   
		   } else if (name === name00 + "分享屏幕") {
			   
		       this.props.DesktopOnlineClick()
		   } else {
		       
		   }
	}
	drawCircle = () => {
		
			const canvas = this.canvasRef.current;
			const context = canvas.getContext('2d');
			// 清空画布
			context.clearRect(0, 0, canvas.width, canvas.height);
	       const { MirrorOnline,DesktopOnline,name } = this.props;
		    context.globalAlpha = sessionStorage.getItem("setBrightness")/100; // 0.0 (完全透明) 到 1.0 (完全不透明)
		   const name00=sessionStorage.getItem("userName")
			 if (name === null || name === undefined || name === "") {
				         // 延迟 3 秒后重新判断
				  setTimeout(this.drawCircle, 1000);
			  }else if (name === name00) {
		           context.fillStyle = MirrorOnline ? 'green' : 'red';
		      } else if (name === name00 + "分享屏幕") {
		           context.fillStyle = DesktopOnline ? 'green' : 'red';
		      } else {
		           context.fillStyle =  'green';
		      }
	       context.beginPath();
		   
	       context.arc(20, 20, 10, 0, Math.PI * 2); // 圆心在 (10, 10)，半径为 5
	       context.fill();
	       context.stroke(); // 可选：绘制边框
	   }
	
	
	
   
    componentWillUnmount = () => {
        this.video.srcObject = null;
    }

    render = () => {
        const { id,vidFit,name,muted ,hostStatelocal,MirrorOnline,	DesktopOnline ,} = this.props;
        const fitClass = vidFit ? "fit-vid" : "";

        return (
            <div className={this.state.displayMainVideo?"main-video-layout":"main-video-layout2"} >
				
				<div className="small-canvas" id="container">
				    {/* Canvas不设置样式 */}
					<video
					    ref={ref =>{
					        this.video = ref;
					    }}
						 muted={hostStatelocal}
					    id={id}
					    autoPlay
					    playsInline
					    className={"resizebox  main-video-size " + fitClass}
					/>
				    <canvas ref='canvas' id="canvasshow"></canvas>
					<canvas ref='canvas2' id="canvasshow2" style={{display: 'none'}}></canvas>
					 <a className="main-video-name-a" onClick={this.changeColor} >{name} <canvas ref={this.canvasRef} width="40" height="40"></canvas></a>
				</div>
                
               
              
            </div>
        )
    }
}

export default BigVideoComponent;

 // {
 //                    muted?
 //                    <div className='big-video-avatar'>
 //                        <Avatar size={156} icon="user"/>
 //                    </div>
 //                    : ""
 //                }