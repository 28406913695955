import React, { Component } from 'react';
import Pusher from 'aliyun-rts-pusher';
import md5 from 'md5'; // 导入 md5 库

const pushClient = AliRTSPusher.createClient();

class Streamer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPushing: false
    };

    pushClient.on('error', (err) => { console.log(err.errorCode) });

    this.startScreenCapture = async () => {
      const streamId = await pushClient.startScreenCapture();
      return streamId;
    }

    this.startPushStream = async () => {
      try {
        const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
		pushClient.setVideoQuality('1080p_1');
        pushClient.startMicrophone(audioStream);
        pushClient.startPush(this.pushUrl('out.xytechsz.com', 'd97xDsyH0bHUCvjF', 86400, 80035, 80035));
		console.log(this.pushUrl('out.xytechsz.com', 'd97xDsyH0bHUCvjF', 86400, 80035, 80035))
		console.log(this.pushUrl('out.xytechsz.com', 'd97xDsyH0bHUCvjF', 86400, 80035, 80035))
        this.setState({ isPushing: true });

        // // 设置视频流的渲染目标为 videoContainer
        // const videoEl = pushClient.setRenderView('videoContainer');
        // videoEl.muted = true;
      } catch (error) {
        console.error('Error accessing audio stream:', error);
      }
    }

    this.stopPushStream = () => {
      pushClient.stopMicrophone();
      pushClient.stopPush();
      this.setState({ isPushing: false });
    }
  }

 pushUrl = (pushDomain, pushKey, expireTime, appName, streamName) => {
   const timeStamp = Math.floor(Date.now() / 1000) + parseInt(expireTime);
 
   let pushUrl;
   if (pushKey!== '') {
     const sstring = `/${appName}/${streamName}-${timeStamp}-0-0-${pushKey}`;
     pushUrl = `artc://${pushDomain}/${appName}/${streamName}?auth_key=${timeStamp}-0-0-${md5(sstring)}`;
   } else {
     pushUrl = `artc://${pushDomain}/${appName}/${streamName}`;
   }
   return pushUrl;
 }

  componentDidMount() {
    this.startScreenCapture(); // 开始屏幕共享
  }

  render() {
    return (
      <div>
        <div id="videoContainer"></div>
        {this.state.isPushing? (
          <button onClick={this.stopPushStream}>停止推流</button>
        ) : (
          <button onClick={this.startPushStream}>开始推流</button>
        )}
      </div>
    );
  }
}

export default Streamer;