// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../image/logo.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".login-main{height:100%;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-position:top;background-size:100% auto;background-attachment:fixed;justify-content:center;align-items:center}.login-bg-yk{display:flex;justify-content:center;text-align:center;padding-top:10px;border:1px solid #dcdcdc;background-position:top;background-size:100% auto;background-attachment:fixed;justify-content:center;align-items:center;margin-left:35%;margin-right:35%}.login-bg{display:flex;padding-top:10%;background-position:top;background-size:100% auto;background-attachment:fixed;justify-content:center;align-items:center}.login-bg2{background-color:#dcdcdc;display:flex;margin-left:35%;margin-right:35%;border-radius:20px;padding-top:20px;background-position:top;background-size:100% auto;background-attachment:fixed;justify-content:center;align-items:center}.login-title{text-align:center;padding:20px 0;font-size:24px;color:#323232;letter-spacing:1px;font-family:\"微软雅黑\"}button{font-size:26px}.login-form{width:600px;background-color:#fff;border:1px solid #f9f9f9;border-radius:4px}.login-input-icon{color:rgba(0,0,0,.25)}.login-join-button{width:100%}.div-show{padding:0;margin:0 auto;width:150px;background:#dcdcdc;height:100px;text-align:center;margin-top:10px;line-height:100px;border-radius:10px;display:inline;padding:31px;margin-left:20px}.div-show1{padding:0;margin:0 auto;width:100px;height:100px;text-align:center;margin-top:200px;line-height:100px}.div-show3{text-align:center}", ""]);
// Exports
module.exports = exports;
