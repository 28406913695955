import React from "react";
import { Form, Icon, Input, Button, Card } from "antd";
import { reactLocalStorage } from "reactjs-localstorage";
import "../styles/css/login.scss";


class LoginPage extends React.Component {

  componentDidMount = () => {
    const { form } = this.props;
    let params = this.getRequest();
    let roomId = 'room1';
    let displayName = '';

    let localStorage = reactLocalStorage.getObject("loginInfo");

    if (localStorage) {
      roomId = localStorage.roomId;
      displayName = localStorage.displayName;
      console.log('localStorage:' + roomId + ' ' + displayName);
    }

    if (params && params.hasOwnProperty('room')) {
      roomId = params.room;
    }

    form.setFieldsValue({
      'roomId': roomId,
      'displayName': displayName,
	  'displayPassword':'123456'
    });

  };

  componentWillUnmount = () => {
    this.cleanup();
  }

  cleanup = async () => {
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const handleLogin = this.props.handleLogin;
        handleLogin(values);
        console.log("登录表单里的值为: ", values);
      }
    });
  };

  getRequest() {
    let url = location.search;
    let theRequest = new Object();
    if (url.indexOf("?") != -1) {
      let str = url.substr(1);
      let strs = str.split("&");
      for (let i = 0; i < strs.length; i++) {
        theRequest[strs[i].split("=")[0]] = decodeURI(strs[i].split("=")[1]);
      }
    }
    return theRequest;
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="login-bg">
        <Form onSubmit={this.handleSubmit} className="login-form" style={{ padding: '20px' }}>
        <h1 className="login-title">病理协作系统</h1>
          <Form.Item>
            {getFieldDecorator("roomId", {
              rules: [{ required: true, message: "请输入房间Id" }]
            })(
              <Input
                prefix={<Icon type="team" className="login-input-icon" />}
                placeholder="房间Id"
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("displayName", {
              rules: [{ required: true, message: "请输入用户名" }]
            })(
              <Input
                prefix={
                  <Icon type="contacts" className="login-input-icon" />
                }
                placeholder="用户名"
              />
            )}
          </Form.Item>
		  <Form.Item>
		    {getFieldDecorator("displayPassword", {
		      rules: [{ required: true, message: "请输入密码" }]
		    })(
		      <Input
		        prefix={
		          <Icon type="safety" className="login-input-icon" />
		        }
		        placeholder="请输入密码"
		  				
		      />
		    )}
		  </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-join-button">
              加入视频诊断
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const WrappedLoginForm = Form.create({ name: "login" })(LoginPage);
export default WrappedLoginForm;
