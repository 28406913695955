import React from "react";
import MicrophoneOffIcon from "mdi-react/MicrophoneOffIcon";
import VideocamOffIcon from "mdi-react/VideocamOffIcon";
import { Avatar,Icon,Button } from 'antd';

class LocalVideoComponent extends React.Component{
    constructor(props){
        super(props);
		 this.canvasRef = React.createRef(); // 创建一个 ref 来引用 canvas
        this.state = {
  displayMainVideo:true
       };
  	
  }
   handleshow() {
          this.setState({
              displayMainVideo:true
          })
      }
      handlehide() {
          this.setState({
              displayMainVideo:false
          })
      }
    
    componentDidMount = () => {
		  this.drawCircle(); // 组件挂载后绘制圆圈
        const {stream} = this.props;
        this.video.srcObject = stream;
    }
	componentDidUpdate(prevProps) {
		  // console.log("componentDidUpdate called with MeshOnline:", MeshOnline); // 添加日志
	      // 当 MeshOnline 的值变化时重新绘制圆圈
		  const { MeshOnline } = this.props;
	      if (prevProps.MeshOnline !== MeshOnline) {
	           this.drawCircle();
	      }
	  }
     drawCircle = () => {
		  // console.log("Drawing circle with MeshOnline:", this.props.MeshOnline); // 添加日志
            const { MeshOnline } = this.props;
			console.log(MeshOnline)
            const canvas = this.canvasRef.current;
            const context = canvas.getContext('2d');
    
            // 清空画布
            context.clearRect(0, 0, canvas.width, canvas.height);
    
            // 设置圆圈颜色
            context.fillStyle = MeshOnline ? 'green' : 'red';
    
            // 绘制圆圈
            context.beginPath();
            context.arc(20, 20, 10, 0, Math.PI * 2); // 圆心在 (10, 10)，半径为 5
            context.fill();
            context.stroke(); // 可选：绘制边框
        }
    componentWillUnmount = () => {
        this.video.srcObject = null;
    }

    render = () => {
        const { id,name,audioMuted,videoMuted,MeshOnline } = this.props;
		// console.log("YourComponent MeshOnline:", MeshOnline); // 添加日志                     
        return (
		
            <div  className={this.state.displayMainVideo?"local-video-container":"local-video-container2"}   style={{borderWidth: '0.5px'}}>
                <video
					onClick={this.props.onChangeVideoLocalPosition}
                    ref={ref =>{
                        this.video = ref;
                    }}
                    id={id}
                    autoPlay
                    playsInline
                    muted={true}
                    className="local-video-size"
                />
                 
                <div className='local-video-icon-layout'>
                    {audioMuted && <MicrophoneOffIcon size={18} color="white"/>}
                    {videoMuted && <VideocamOffIcon size={18} color="white"/>}
                </div>
				
                <a className="local-video-name" onClick={this.props.MeshOnlineClick}>{name} <canvas ref={this.canvasRef} width="40" height="40"></canvas></a>
            </div>
        )
    }
}

export default LocalVideoComponent;